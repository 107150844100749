import React, { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import moment from 'moment'
import Tooltip from './Tooltip'

const formatDate = (d, hours = true) => {
  const m = new moment.unix(d)
  if (hours) {
    return m.format('D/M HH:mm')
  }
  return m.format('D/M/YY')
}
const formatValue = d3.format('')
const margin = { top: 47, right: 5, bottom: 23, left: 5 }
const tooltipRectParams = { x: 5.0, y: -100, width: 200, height: 84 }
const tooltipCircleParams = { cx: 0, cy: 0, r: 3 }
const tooltipKeyParams = { x: 20, y: -23 }
const tooltipValueParams = { x: 100, y: -23 }
const tooltipKey2Params = { x: 20, y: -43, fill: '#4c4c4c' }
const tooltipValue2Params = { x: 20, y: -63, fill: '#4c4c4c' }

// ** TODO, use the proper colours
const lineColours = ['#ff0066', '#1482c8', '#28b4a0', '#1e5096', '#78005a']

const indexById = (data, channelId) => {
  let i = 0
  for (; i < data.length; ++i) {
    if (data[i].channelId === channelId) {
      break
    }
  }
  return i
}
const lineColorById = (data, channelId) => lineColours[indexById(data, channelId)]

const showTooltip = (width, x, y, hours) => function (d) {
  if (!d) {
    return
  }
  let _x = x(d.date), _y = y(d.value), _cx = 0, _cy = 0
  if (_y < -this.tooltipRectParams.y) {
    _y = -this.tooltipRectParams.y
    _cy = y(d.value) - _y
  }
  if (_x > width - this.tooltipRectParams.width - this.tooltipRectParams.x - margin.right) {
    _x = x(d.date) - this.tooltipRectParams.width - 2 * this.tooltipRectParams.x
    _cx = x(d.date) - _x
  }

  this.node.setAttribute('transform', `translate(${_x},${_y})`)
  this._circle.setAttribute('transform', `translate(${_cx},${_cy})`)
  this._circle.setAttribute('fill', lineColours[d.index])
  this._key.textContent = formatDate(d.date, hours)
  this._value.textContent = formatValue(d.value, hours)
  this._value.setAttribute('fill', lineColours[d.index])
  this._key2.textContent = `${d.channelName}`
  this._value2.textContent = `${d.metric}`
  this._value2.setAttribute('fill', tooltipValue2Params.fill)
}

const isHours = data => {
  for (let i = 1; i < data.length; ++i) {
    if (data[i].date - data[i - 1].date < 86400000) {
      return true
    }
  }
  return false
}

function wrap (width, padding) {
  return function () {
    var self = d3.select(this),
      textLength = self.node().getComputedTextLength(),
      text = self.text()
    while (textLength > (width - 2 * padding) && text.length > 0) {
      text = text.slice(0, -1)
      self.text(text + '...')
      textLength = self.node().getComputedTextLength()
    }
  }
}

const MultiLine = ({ data = [], data2 = [], width, height }) => {
  // each item in data is an array of data for one channel
  const hours = data.every(item => isHours(item.data))

  const d3Container = useRef(null)
  useEffect(() => {
    if (height && width && data && data.length > 0 && d3Container.current) {
      // only top 5
      // **TODO - make sure we get the right order from the query in the first place
      // (how when the count can vary per time  slice? What defines the 'top 5 channels'
      // count summed over the whole selected time interval?)

      // For now, lets just do *something*
      let top5 = data.slice(0, 5)

      // get the max value for the y axis for all lines
      //const flattened = top5.map((x, index) => x.data.flat()).flat()
      let flattened = [], flattened2 = []
      for (let i = 0; i < top5.length; ++i) {
        flattened = flattened.concat(top5[i].data.map(item => ({ ...item, index: i })))
      }

      let top5_2 = []
      for (let i = 0; i < top5.length; ++i) {
        const item2 = data2.find(el => el.channelId === top5[i].channelId)
        if (item2) {
          top5_2.push(item2)
        }
      }
      for (let i = 0; i < data2.length && top5_2.length < 5; ++i) {
        const item = top5_2.find(el => el.channelId === data2[i])
        if (!item) {
          top5_2.push(data2[i])
        }
      }
      for (let i = 0; i < top5_2.length; ++i) {
        flattened2 = flattened2.concat(top5_2[i].data.map(item => ({ ...item, index: i })))
      }

      const max = d3.max(flattened.concat(flattened2), d => d.value)
      const y = d3.scaleLinear()
        .domain([0, max])
        .range([height - margin.bottom, margin.top])

      const dataArray = top5.map(d => d.data.map(x => { return { ...x, date: new Date(x.date) } }))
      const dataArray2 = top5_2.map(d => d.data.map(x => { return { ...x, date: new Date(x.date) } }))

      const x = d3.scaleUtc()
        .domain(d3.extent(flattened, d => new Date(d.date)))
        .range([margin.left, width - margin.right])
      const x2 = d3.scaleUtc()
        .domain(d3.extent(flattened2, d => new Date(d.date)))
        .range([margin.left, width - margin.right])

      const line = d3.line()
        .defined(d => !isNaN(d.value))
        .x(d => x(d.date))
        .y(d => y(d.value))
      const line2 = d3.line()
        .defined(d => !isNaN(d.value))
        .x(d => x2(d.date))
        .y(d => y(d.value))

      const bisect = (data, date, value) => {
        let minDist = Number.MAX_VALUE
        let element
        const dateForCalc = date / 1000000
        for (let i = 0; i < data.length; ++i) {
          const itemDateForCalc = data[i].date / 1000000
          const dist = Math.sqrt((itemDateForCalc - dateForCalc) * (itemDateForCalc - dateForCalc) + (data[i].value - value) * (data[i].value - value))
          if (dist < minDist) {
            minDist = dist
            element = data[i]
          }
        }
        if (element) {
          if (minDist < 100) {
            element.tooltip = true
          } else {
            element.tooltip = false
          }
        }
        return element
      }

      const xAxisLine = g => g
        .attr(`transform`, `translate(0,${height - margin.bottom})`)
        .style(`font-size`, `12px`)
        .style(`color`, `#979ba0`)
        .call(d3.axisBottom(x).tickSize(0).tickFormat(() => ''))

      // ** TODO sort this out
      const xTicks = 3

      const xAxisLabels = g => g
        .attr(`transform`, `translate(0,${height - margin.bottom + 7})`)
        .style(`font-size`, `12px`)
        .style(`color`, `#979ba0`)
        .attr('stroke-width', 0)
        .call(d3.axisBottom(x).ticks(xTicks).tickSize(0).tickFormat(d => formatDate(d, hours)))

      const yAxis = g => g
        .attr('transform', `translate(${margin.left},0)`)
        .style(`font-size`, `12px`)
        .style(`color`, `#979ba0`)
        .attr('stroke-width', 0)
        .call(d3.axisRight(y).ticks(2).tickSize(0).tickFormat((y, i) => i === 0 ? '' : y))

      const tooltip = new Tooltip(
        {
          d3,
          tooltipRectParams,
          tooltipCircleParams,
          tooltipKeyParams,
          tooltipValueParams,
          tooltipKey2Params,
          tooltipValue2Params,
          _show: showTooltip(width, x, y, hours)
        })

      const svg = d3.select(d3Container.current)
      svg.selectAll('*').remove()
      svg.attr('viewBox', [0, 0, width, height])
        .attr('font-family', 'Roboto, Helvetica, Arial, sans-serif')
        .style('font-size', '12px')

      // legend:
      if (top5.length) {
        // Note we use the top level 'data' here as passed in as we only need the channelId (**TODO use name??)
        // eslint-disable-next-line
        for (const [index, item] of top5.entries()) {
          svg.append('circle')
            .attr('class', 'legendcircle')
            .attr('cx', margin.left + 3 + width / 5 * index)
            .attr('cy', 20).attr('r', 2.5)
            .style('fill', lineColours[index])
          svg.append('text')
            .attr('class', 'legendtext')
            .attr('id', 'legendtext' + index)
            .attr('x', margin.left + 10 + width / 5 * index)
            .attr('y', 22).text(item.channelName).each(wrap(width / 5, 5))
            .attr('alignment-baseline', 'middle').attr(`fill`, `#979ba0`)
        }
      }

      // gridlines:
      let ticks = y.ticks(3)
      if (ticks.length === 3) {
        ticks = [ticks[0], (ticks[0] + ticks[1]) / 2, ticks[1], (ticks[1] + ticks[2]) / 2, ticks[2]]
      }

      const gridLines = svg.selectAll('line.horizontalGrid').data(ticks).enter().append('line')
      gridLines.attr('x1', margin.left)
      gridLines.attr('y1', y)
      gridLines.attr('fill', 'none')
      gridLines.attr('stroke', '#f2f4f7')
      gridLines.attr('stroke-width', '1px')

      svg.append('g')
        .call(yAxis)

      svg.append('g')
        .call(xAxisLine)

      svg.append('g')
        .call(xAxisLabels)

      const lines = []
      const circles = []
      for (let i = 0; i < dataArray.length; ++i) {
        lines[i] = svg.append('path')
          .attr('class', 'line')
          .attr('id', 'line' + i)
          .datum(dataArray[i])
          .attr('stroke', lineColours[i])
          .attr('fill', 'none')
          .attr('stroke-width', 1.5)
          .attr('stroke-linejoin', 'round')
          .attr('stroke-linecap', 'round')
          .attr('d', line)

        circles[i] = svg.selectAll('myCircles')
          .data(dataArray[i])
          .enter()
          .append('circle')
          .attr('fill', lineColours[i])
          .attr('stroke', 'none')
          .attr('cx', d => x(new Date(d.date)))
          .attr('cy', d => y(d.value))
          .attr('r', 2.5)
          .attr('display', 'none')
      }

      const lines2 = []
      const circles2 = []
      for (let i = 0; i < dataArray2.length; ++i) {
        lines2[i] = svg.append('path')
          .attr('class', 'line')
          .attr('id', 'line' + i)
          .datum(dataArray2[i])
          .attr('stroke', lineColorById(data, top5_2[i].channelId))
          .attr('fill', 'none')
          .attr('stroke-width', 1.5)
          .attr('stroke-linejoin', 'round')
          .attr('stroke-linecap', 'round')
          .attr('stroke-dasharray', '1, 2')
          .attr('d', line2)
          .style('opacity', 0.2)

        circles2[i] = svg.selectAll('myCircles')
          .data(dataArray2[i])
          .enter()
          .append('circle')
          .attr('fill', lineColorById(data, top5_2[i].channelId))
          .attr('stroke', 'none')
          .attr('cx', d => x2(new Date(d.date)))
          .attr('cy', d => y(d.value))
          .attr('r', 2.5)
          .attr('display', 'none')
          .style('opacity', 0.2)
      }

      svg.append(() => tooltip.node)

      svg.on('mousemove', () => {
        const data = bisect(flattened, x.invert(d3.event.offsetX), y.invert(d3.event.offsetY))
        if (data) {
          if (data.tooltip) {
            circles[data.index].attr('display', 'block')
            tooltip.show(data)
          }
          else {
            tooltip.hide()
          }
          lines.forEach((line, i) => i === data.index ? line.style('opacity', 1) : line.style('opacity', 0.2))
          circles.forEach((circle, i) => i === data.index ? circle.attr('display', 'block') : circle.attr('display', 'none'))
          circles2.forEach((circle, i) => i === indexById(top5_2, data.channelId) ? circle.attr('display', 'block') : circle.attr('display', 'none'))
        } else {
          tooltip.hide()
          circles.forEach(circleSet => circleSet.attr('display', 'none'))
          circles2.forEach(circleSet => circleSet.attr('display', 'none'))
          lines.forEach(line => line.style('opacity', 0.2))
        }
      })
      svg.on('mouseleave', () => {
        tooltip.hide()
        circles.forEach(circleSet => circleSet.attr('display', 'none'))
        circles2.forEach(circleSet => circleSet.attr('display', 'none'))
        lines.forEach(line => line.style('opacity', 1))
      })
    }
  }, [data, data2, height, width, hours])

  return <svg width={width} height={height} ref={d3Container} />
}

export default MultiLine
