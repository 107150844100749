import React, { useRef, useEffect, useState } from 'react'
import * as d3 from 'd3'
import Tooltip from './Tooltip'
import uuid from 'uuid/v4'

const margin = { top: 5, right: 5, bottom: 9, left: 5 }
const barHeight = 6

const tooltipRectParams = ({ x: 0, y: 0, width: 140, height: 42 })
const tooltipKeyParams = ({ x: 10, y: 17 })
const tooltipValueParams = ({ x: 10, y: 37 })

const showTooltip = (chartWidth, y, data) => function (d) {
  this._key.textContent = data[d._j].metric
  this._value.textContent = data[d._j].value
  const width = Math.max(this._key.getBoundingClientRect().width, this._value.getBoundingClientRect().width) + 20
  this.rect.setAttribute('width', width)
  let _x = d._x
  if (_x + width + margin.right > chartWidth) {
    _x = chartWidth - width - margin.right
  }
  this.node.setAttribute('transform', `translate(${_x},${d._j * y.step()})`)
}

const scaleBandInvert = scale => {
  var domain = scale.domain()
  var paddingOuter = scale(domain[0])
  var eachBand = scale.step()
  return function (value) {
    var index = Math.floor(((value - paddingOuter) / eachBand))
    return domain[Math.max(0, Math.min(index, domain.length - 1))]
  }
}

const HorizontalBars = ({ data = [], width, height }) => {
  data.sort((a, b) => b.value - a.value)

  if (data.length > 10) {
    data.length = 10
  }

  const [currentReportId] = useState(uuid())

  const d3Container = useRef(null)
  useEffect(() => {
    if (height && width && data && d3Container.current) {
      const x = d3.scaleLinear()
        .domain([0, d3.max(data, d => d.value)])
        .range([margin.left, width - margin.right])
      const y = d3.scaleBand()
        .domain(d3.range(data.length))
        .rangeRound([margin.top, height - margin.bottom])
      const xAxis = g => g
        .attr(`transform`, `translate(0,${height})`)
        .style(`font-size`, `12px`)
        .style(`color`, `#979ba0`)
        .call(d3.axisTop(x).ticks(5).tickSize(0))
        .call(g => g.select(`.domain`).remove())

      const tooltip = new Tooltip(
        {
          d3,
          tooltipRectParams,
          tooltipCircleParams: null,
          tooltipKeyParams,
          tooltipValueParams,
          _show: showTooltip(width, y, data)
        })

      const svg = d3.select(d3Container.current)
      svg.selectAll(`*`).remove()
      svg.attr(`viewBox`, [0, 0, width, height])
        .attr(`font-family`, `Roboto, Helvetica, Arial, sans-serif`)
        .style(`font-size`, `12px`)

      // gridlines:
      let ticks = x.ticks(5)
      const gridLines = svg.selectAll(`line.verticalGrid`).data(ticks).enter().append(`line`)
      gridLines.attr(`x1`, x)
      gridLines.attr(`x2`, x)
      gridLines.attr(`y1`, margin.top)
      gridLines.attr(`y2`, height - margin.top)
      gridLines.attr(`fill`, `none`)
      gridLines.attr(`stroke`, `#f2f4f7`)
      gridLines.attr(`stroke-width`, `1px`)

      svg.append(`g`)
        .attr(`fill`, `#FFB2D1`)
        .selectAll(`rect`)
        .data(data)
        .join(`rect`)
        .attr(`id`, (d, i) => `bar_${currentReportId}_${i}`)
        .attr(`x`, x(0))
        .attr(`y`, (d, i) => y(i))
        .attr(`width`, d => x(d.value) - x(0))
        .attr(`height`, barHeight)
        .call(el => el.filter((x, i) => !(i % 2))
          .attr(`fill`, `#FF0066`))

      svg.append(`g`)
        .attr(`text-anchor`, `start`)
        .attr(`font-family`, `sans-serif`)
        .attr(`font-size`, 12)
        .selectAll(`text`)
        .data(data)
        .join(`text`)
        .attr(`id`, (d, i) => `text_${currentReportId}_${i}`)
        .attr(`x`, margin.left + 5)
        .attr(`y`, (d, i) => y(i) + y.bandwidth() / 2)
        .attr(`dy`, `0.35em`)
        .attr(`dx`, -4)
        .text(d => d.name)

      svg.append(`g`)
        .call(xAxis)

      svg.append(() => tooltip.node)

      svg.on('mousemove', () => {
        const _j = scaleBandInvert(y)(d3.event.offsetY)
        tooltip.show({ _j, _x: d3.event.offsetX })

        for (let i = 0; i < data.length; ++i) {
          d3.select(`#bar_${currentReportId}_${i}`).style('opacity', i === _j ? 1 : 0.1)
          d3.select(`#text_${currentReportId}_${i}`).style('opacity', i === _j ? 1 : 0.3)
        }
      })
      svg.on('mouseleave', () => {
        tooltip.hide()
        for (let i = 0; i < data.length; ++i) {
          d3.select(`#bar_${currentReportId}_${i}`).style('opacity', 1)
          d3.select(`#text_${currentReportId}_${i}`).style('opacity', 1)
        }
      })
    }
  },
    [data, height, width, currentReportId])
  return <svg width={width} height={height} ref={d3Container} />
}

export default HorizontalBars