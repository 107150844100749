import token from './token'
import { shallowEqual } from 'fast-equals'
import { ExtendedError } from './errors'
/**
 * TODO: This schould be covered by the build process
 */
let backendUrl = ''
const url = new URL(window.location.origin)

if (url.port === '3001') {
  backendUrl = `http://${url.hostname}:3000`
} else {
  backendUrl = window.location.origin
}
const tokenExpiredError = {
  code: 401,
  message: "Request cannot be authenticated. Invalid token.",
  name: "UnAuthorizedError",
  type: "Unauthorized"
}

class Backend {
  constructor(url) {
    this.url = url
    this.list = this.list.bind(this)
    this.get = this.get.bind(this)
    this.create = this.create.bind(this)
    this.update = this.update.bind(this)
    this.delete = this.delete.bind(this)
    this.getAppMetadata = this.getAppMetadata.bind(this)
    this.updateAppMetadata = this.updateAppMetadata.bind(this)
    this.getReport = this.getReport.bind(this)
  }

  async autorefreshingFetch (url, paramsCreator, isRetry = false) {
    let needRetry = false
    let result = await fetch(url, paramsCreator(window.localStorage.getItem('token')))
    if (result.status === 401) {
      try {
        const responseBody = await result.text()
        const responseBodyObject = JSON.parse(responseBody)
        if (shallowEqual(responseBodyObject, tokenExpiredError) && !isRetry) {
          needRetry = true
        }
      } catch (ex) {
        // nothing to do here - most likely it is parsing error, but, anyway - just return result in this case
      }
    }
    if (needRetry) {
      let refreshToken = window.localStorage.getItem('refresh_token')
      const res = await fetch(`${this.url}/api/refresh`, {
        method: 'POST',
        body: JSON.stringify({ refresh_token: refreshToken }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if (!res.ok) {
        const text = await res.text()
        if (!window.location.href.includes('/login')) {
          window.location.replace(window.location.origin + '/login')
        }
        throw new Error('Cannot refresh: ' + text)
      }
      const text = await res.text()
      const object = JSON.parse(text)
      window.localStorage.setItem('token', object.access_token)
      result = fetch(url, paramsCreator(object.access_token))
    }
    return result
  }

  async sendVerificationEmail (email) {
    const res = await fetch(`${this.url}/api/sendVerificationEmail`, {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    if (!res.ok) {
      throw new Error(
        'Cannot send verification email'
      )
    }

    return res
  }

  async sendPasswordReset (email) {
    const res = await fetch(`${this.url}/api/sendPasswordReset`, {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    const text = await res.text()
    if (!res.ok) {
      throw new Error(
        'Cannot send password reset to ' + email || '<not specified> ' + text
      )
    }

    const object = JSON.parse(text)
    return object
  }

  async request ({ path, query = {}, method = 'GET', body, isRetry = false }) {
    const serialize = function(obj, prefix) {
      var str = [], p;
      for (p in obj) {
        if (obj.hasOwnProperty(p)) {
          const k = prefix ? prefix + "[" + p + "]" : p, v = obj[p]
          if(typeof v !== 'undefined') {
            str.push((v !== null && typeof v === "object") ? serialize(v, k) : k + "=" + encodeURIComponent(v))
          }
        }
      }
      return str.join("&");
    }
    const url = new URL(`${this.url}/api/v2/${path}?${serialize(query)}`.replaceAll(/\?$/g, ''))
    const params = token => ({
      method: method,
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: body ? JSON.stringify(body) : undefined
    })

    const response = await this.autorefreshingFetch(url, params, isRetry)
    let responseBody
    let responseBodyObject
    responseBody = await response.text()

    if (!response.ok) {
      throw new Error(
        `Request failed: ${response.status} - ${response.statusText} - ${responseBody}`
      )
    }

    if (responseBody === '') {
      return ''
    }

    responseBodyObject = JSON.parse(responseBody)
    responseBodyObject.totalCount = Number(response.headers.get('x-total-count'))
    return responseBodyObject
  }

  async inviteUser (data) {
    return this.request({
      path: `users/invite`,
      method: 'POST',
      body: data
    })
  }

  async notifyLoginNoRole ({ company, email }) {

    const res = await fetch(`${this.url}/api/notifyLoginNoRole`, {
      method: 'POST',
      body: JSON.stringify({ company, email }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    if (!res.ok) {
      const text = await res.text()
      throw new Error('Cannot send notifyLoginNoRole notification: ' + text)
    }
  }

  async login (email, password, pkce) {

    const response = await fetch(`${this.url}/api/login`, {
      method: 'POST',
      body: JSON.stringify({
        email: email,
        password: password,
        verifier: pkce.verifier,
        challenge: pkce.challenge
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const responseJSON = await response.json()

    if (!response.ok) {
      throw new ExtendedError(responseJSON.message || 'Backend Error', responseJSON)
    }

    // Disallow login for users with no role
    const user = token.loadUserFromToken(responseJSON.access_token)
    if (!user || Object.keys(user).length === 0) {
      throw new Error('User object is null or empty')
    }

    if (!user.role || user.role === '') {
      this.notifyLoginNoRole(user)

      throw new Error('Admin must first set a role for this user.')
    }
    return responseJSON
  }

  async register (company, email, password) {
    const res = await fetch(`${this.url}/api/register`, {
      method: 'POST',
      body: JSON.stringify({
        company: company,
        email: email,
        password: password
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (!res.ok) {
      const text = await res.text()
      throw new Error('Cannot register: ' + text)
    }
    const text = await res.text()
    return JSON.parse(text)
  }

  async toggleDashboardItem ({ chart, system, timing }) {
    if (!chart || !system || !timing) {
      throw new Error('chart, system, timing needed')
    }
    const dashboardItems = (await backend.getAppMetadata()).dashboardItems || []
    let updatedDashboardItems = dashboardItems.filter(item => {
      if (
        item.chart === chart &&
        item.system === system &&
        item.timing === timing
      ) {
        return false
      } else return item
    })
    if (updatedDashboardItems.length === dashboardItems.length) {
      updatedDashboardItems.push({
        system,
        chart,
        timing
      })
    }
    const user = token.loadUserFromToken()
    return this.request({
      path: `users/${user.id}/app_metadata`,
      method: 'PATCH',
      body: { dashboardItems: updatedDashboardItems }
    })
  }

  async getAppMetadata () {
    const user = token.loadUserFromToken()
    let appMetadata = await this.request({
      path: `users/${user.id}/app_metadata`,
      method: 'GET'
    })
    return appMetadata
  }

  async updateAppMetadata (appMetadata) {
    const user = token.loadUserFromToken()
    return this.request({
      path: `users/${user.id}/app_metadata`,
      method: 'PATCH',
      body: appMetadata
    })
  }

  async list ({ resource, limit, offset, search, sort, status, userOnly, type, titleId, externalResourceId, createdBySystemId, ...query }) {
    return this.request({
      path: `${resource}`,
      query: {
        limit,
        offset,
        search,
        sort,
        status,
        'user-only': userOnly,
        type,
        titleId,
        externalResourceId,
        createdBySystemId,
        ...query
      }
    })
  }

  async query (resource, query) {
    return this.request({
      path: `${resource}`,
      query: query
    })
  }

  async getHashForProfile (profile) {
    return this.request({
      path: 'channels/getHashForProfile',
      method: 'post',
      body: profile
    })
  }

  async getStripePublicKey () {
    return this.request({
      path: 'tenants/stripePublicKey',
      method: 'get'
    })
  }

  async putChannelAsset ({ titleId, channelId, mediaUrl, name, profile, channelType, doNotRunJob, cmaf, http, hasSeparateAudio, workflowParams }) {
    return this.request({
      path: 'channel-assets',
      method: 'put',
      body: {
        id: titleId,
        channelId,
        channelType,
        profile,
        doNotRunJob,
        source: mediaUrl,
        name: name,
        titleType: 'ad-slate',
        cmaf,
        http,
        hasSeparateAudio,
        workflowParams
      }
    })
  }

  /**
   * Gets data from backend. URL format is `${backendUrl}/api/v2/${resource}/${id}`.
   *
   * @param {string} resource
   * @param {string} id
   * @param {string} doNotThrow if we do not want any exceptions from this function to be thrown then set it to true
   * @return {string|Promise<string>}
   *
   * @example
   *
   *     backend.get('tenants', 'dummy tenant', true)
   */
  async get (resource, id, doNotThrow = false) {
    // tenant passes name, not id, so uri needs encoding
    // iffy bodge
    if (resource === 'title') {
    }
    let uri = encodeURI(`${backendUrl}/api/v2/${resource}/${id ? id : ''}`)
    const response = await this.autorefreshingFetch(uri, token => ({
      headers: {
        Authorization: 'Bearer ' + token
      }
    }))

    if (!doNotThrow) {
      if (!response.ok) {
        const err = new Error(response.statusText)
        err.response = response
        throw err
      }
      return response.json()
    }

    if (response.ok) {
      const text = await response.text()
      let json
      try {
        json = await JSON.parse(text)
        return json
      } catch (ex) {
        return text
      }
    } else {
      const errorJSON = await response.json()
      return errorJSON
    }
  }

  async getReport ({ system, chart, interval = 24 * 60 * 60 * 1000, start, end, channels = '', channelIds = [], campaignName = '' }) {
    end = end || new Date().getTime()
    start = start || end - 24 * 60 * 60 * 1000
    if (Array.isArray(channels)) {
      channels = channels.join(',')
    }
    if (!channels) {
      channels = ''
    }

    const response = await this.autorefreshingFetch(
      `${backendUrl}/api/v2/systems/${system}/reports/${chart}`,
      token => ({
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ system, interval, start, end, channels, channelIds, campaignName })
      })
    )
    return response.json()
  }

  async getMessageFromResponse (response, prefix) {
    const text = await response.text()
    let message
    let data
    if (text) {
      try {
        const obj = JSON.parse(text)
        message = obj.message
        data = obj.data ? ' - ' + JSON.stringify(obj.data) : ''
        if (typeof obj.data === 'string') {
          data = ' - ' + obj.data
        }
        if (obj.data && typeof obj.data.message === 'string') {
          data = ' - ' + obj.data.message
        }
      } catch (e) {
        // we dont care just need to not error from parsing
      }
    }
    return prefix + message + data
  }
  async delete (resource, id, body) {
    let url = encodeURI(`${backendUrl}/api/v2/${resource}${id?'/':''}${id}`)
    if (typeof body === 'object') {
      url += '?'
      Object.keys(body).forEach(key => {
        url += key + '=' + body[key] + '&'
      })
    }
    const response = await this.autorefreshingFetch(url,
      token => {
        const params = {
          method: 'DELETE',
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
        return params
      })

    if (!response.ok) {
      const message = await this.getMessageFromResponse(response, ' - ')
      throw new Error('Cannot delete ' + resource + ' - ' + response.statusText + message)
    }
    return response.json()
  }

  async create (resource, body) {
    const response = await this.autorefreshingFetch(`${backendUrl}/api/v2/${resource}`, token => ({
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }))

    let responseBody
    if (!response.ok) {
      const message = await this.getMessageFromResponse(response, ' - ')
      let errorMessage = 'Cannot create ' + resource + ' - ' + response.statusText + message
      throw new Error(errorMessage)
    }

    if (!response.locked) responseBody = await response.json()
    return responseBody
  }

  async createWithFormData(resource, body, events = {}) {
    const token = window.localStorage.getItem('token')
    const req = new XMLHttpRequest()
    const onProgress = events.onProgress || function() {}
    const onAbort = events.onAbort || function() {}
    req.open('POST', `${backendUrl}/api/v2/${resource}`)

    await new Promise((resolve, reject) => {
      req.addEventListener('load', function (e) {
        if (req.status >= 200 && req.status <= 299) {
          const response = JSON.parse(e.target.response)
          resolve(response)
        } else {
          try {
            const response = JSON.parse(e.currentTarget.response)
            reject(response.message)
          } catch (ex) {
            console.error('Cannot parse error', e, ex)
          }
        }
      }, false)

      req.addEventListener('error', function (e) {
        reject(e)
      }, false)

      req.upload.addEventListener('progress', function (e) {
        let progress = 0;
        if (e.total !== 0) {
          progress = parseInt(e.loaded / e.total * 100, 10)
        }
        onProgress(progress)
      }, false)

      req.addEventListener('abort', function (e) {
        onAbort(e)
        resolve(null)
      }, false)


      req.setRequestHeader('Authorization', 'Bearer ' + token)

      req.send(body)
    })




    // req.setRequestHeader('filename', name)
    // req.setRequestHeader('profile', JSON.stringify(JSON.parse(profile)))
    // req.setRequestHeader('channeltype', type)
    // req.setRequestHeader('cmaf', cmaf)
    // req.setRequestHeader('http', http)
    // req.setRequestHeader('hasSeparateAudio', hasSeparateAudio)




    // const response = await this.autorefreshingFetch(`${backendUrl}/api/v2/${resource}`, token => ({
    //   method: 'POST',
    //   headers: {
    //     Authorization: 'Bearer ' + token,
    //     'Content-Type': 'multipart/form-data'
    //   },
    //   body: body
    // }))

    // let responseBody
    // if (!response.ok) {
    //   const message = await this.getMessageFromResponse(response, ' - ')
    //   let errorMessage = 'Cannot create ' + resource + ' - ' + response.statusText + message
    //   throw new Error(errorMessage)
    // }

    // if (!response.locked) responseBody = await response.json()
    // return responseBody
  }

  async update (resource, body) {
    const response = await this.autorefreshingFetch(
      `${backendUrl}/api/v2/${resource}/${body.id}`,
      token => ({
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }))

    let responseBody
    if (!response.ok) {
      const message = await this.getMessageFromResponse(response, ' - ')
      let errorMessage = 'Cannot update ' + resource + ' - ' + response.statusText + message
      throw new Error(errorMessage)
    }

    if (!response.locked) responseBody = await response.json()
    return responseBody
  }

  async reset (id) {
    const response = await this.autorefreshingFetch(
      `${backendUrl}/api/v2/channels/reset/${id}`, token => ({
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      }))
    if (!response.ok) {
      const err = new Error(response.statusText)
      err.data = response.json()
      throw err
    }
    return response.json()
  }

  async getProfile ({ sourceManifestUrl, apiKey, manifestAnalysisOnly }) {
    const response = await this.autorefreshingFetch(`${backendUrl}/api/v2/channels/getProfile`, token => ({
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ sourceManifestUrl, apiKey, manifestAnalysisOnly })// apiKey is any that is needed to access the output channel
    }))
    if (response.ok) {
      return response.json()
    } else {
      const error = await response.json()
      return { error }
    }
  }
  async getProxyManifestUrl (sourceManifestUrl, apiKey) {
    const response = await this.autorefreshingFetch(`${backendUrl}/api/v2/channels/getProxyManifestUrl`, token => ({
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ sourceManifestUrl })// apiKey is any that is needed to access the output channel
    }))
    if (response.ok) {
      const returnValue = await response.json()
      return returnValue.proxyManifestUrl
    } else {
      return ''
    }
  }
  async validateSourceManifestUrl (sourceManifestUrl, apiKey) {
    const response = await this.autorefreshingFetch(`${backendUrl}/api/v2/channels/validateSourceManifestUrl`, token => ({
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ sourceManifestUrl, apiKey })// apiKey is any that is needed to access the output channel
    }))
    if (response.ok) {
      return response.json()
    } else {
      const errorJSON = await response.json()
      return { errors: [errorJSON] }
    }
  }

  async schema (resource, item) {
    let url = `${backendUrl}/api/v2/${resource}/schema`
    if (item) url = `${url}?item=${item}`
    const response = await this.autorefreshingFetch(url, token => ({
      headers: {
        Authorization: 'Bearer ' + token
      }
    }))
    return response.json()
  }

  async modelSchema (resource, item) {
    let url = `${backendUrl}/api/v2/${resource}/modelSchema`
    if (item) url = `${url}?item=${item}`
    const response = await this.autorefreshingFetch(url, token => ({
      headers: {
        Authorization: 'Bearer ' + token
      }
    }))
    return response.json()
  }

  getMediaUploadUrl (id) {
    return `${backendUrl}/api/v2/channels/${id}/upload-ad-slate`
  }

  beforeSend ({ req, profile, name, type, cmaf, hasSeparateAudio, http, audioNormalization }) {
    if (!profile) {
      throw new Error('You have to specify a profile before uploading ad slates')
    }
    const token = window.localStorage.getItem('token')
    req.setRequestHeader('Authorization', 'Bearer ' + token)
    req.setRequestHeader('filename', name)
    req.setRequestHeader('profile', JSON.stringify(JSON.parse(profile)))
    req.setRequestHeader('channeltype', type)
    req.setRequestHeader('cmaf', cmaf)
    req.setRequestHeader('http', http)
    req.setRequestHeader('hasSeparateAudio', hasSeparateAudio)
    if (audioNormalization) {
      req.setRequestHeader('audioNormalization', JSON.stringify(audioNormalization))
    }

    return req
  }

  async checkBitmovin (id, apiKey, organizationId) {
    console.log(id, apiKey, organizationId)
    try {
      const result = await this.request({
        path: 'systems/checkBitmovin',
        method: 'get',
        query: { id, apiKey, organizationId }
      })
      return result
    } catch (e) {
      return { status: 'OTHER ERROR', message: e.message }
    }
  }
}

const backend = new Backend(backendUrl)
window.backend = backend
export default backend
